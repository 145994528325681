<template>
  <div class="composite-record">
    <div
      class="list"
      v-if="list.length"
    >
      <div
        class="item"
        v-for="(i, j) in list"
        :key="j"
        @click="goDetail(i)"
      >
        <div class="item-icon">
          <!-- <div class="item-icon-modal">即将开放</div> -->
          <span class="item-icon-container">
            <img
              class="item-icon-container-img"
              :src="i.coverUrl"
            />
          </span>
        </div>
        <div class="infos">
          <div class="name">{{i.displayName}}</div>
          <!-- <div class="count">
            <div class="count-l">
              <img
                src="../assets/icon/composite/limit.png"
                alt=""
              >
              <p>限量</p>
            </div>
            <div class="count-r">
              <p>1000份</p>
            </div>
          </div> -->
          <div class="open">
            <span>合成时间：</span>
            <span class="date">{{i.submitTime | formatDate}}</span>
            <!-- <span class="time">12:30</span> -->
          </div>
        </div>
      </div>
    </div>
    <div
      class="epmty"
      v-else
    >
      <img
        src="../assets/icon/composite/empty.png"
        alt=""
      >
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
import api from '../api/index-client'
export default {
  data () {
    return {
      list: []
    }
  },
  mounted () {
    this.$store.commit('HIDE_APPLOADING')
    this.getData()

  },
  methods: {
    getData () {
      // /api/mall/mergetoken/order
      let param = {
        status: 'FINISHED'
      }
      api
        .get('mall/mergetoken/order',param)
        .then(res => {
          if (res.data.status == 200) {
            this.list = res.data.data.content
          } else {
            this.$toast({
              message: res.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    goDetail (i) {
      setTimeout(() => {
        // this.showMaterial = true
        this.$router.push(`/compositeSelectMaterial?from=record&orderId=${i.id}`)
      },300)
      // sessionStorage.setItem('selectMaterialList',JSON.stringify(this.selectMaterialList))
    },
    goOrderDetails (e) {
      this.$router.push('/orderDetail?orderId=' + e.id + '&type=composite')
    },
  }
}
</script>

<style lang="less" scoped>
.composite-record {
  margin-top: 3.875rem;
  .list {
    margin-top: 1.3125rem;
    .item {
      width: 21.9375rem;
      height: 8.125rem;
      margin: 0 auto 0.75rem;
      background: url('../assets/icon/composite/item_bg.png') 50% 50% no-repeat;
      background-size: 100% auto;
      border-radius: 0.5rem;
      overflow: hidden;
      display: flex;
      color: #ffffff;
      .item-icon {
        width: 8.125rem;
        height: 8.125rem;
        // border-radius: 0.5rem;
        overflow: hidden;
        background: #ffffff;
        position: relative;
        &-container {
          width: 8.125rem;
          height: 8.125rem;
          display: block;
          position: relative;
          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
        }
        .item-icon-modal {
          width: 8.125rem;
          height: 8.125rem;
          // border-radius: 0.5rem;
          overflow: hidden;
          background: rgba(0, 0, 0, 0.6);
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
        }
      }
    }
    .infos {
      margin-left: 0.9375rem;
      .name {
        font-size: 1.125rem;
        margin: 2.25rem 0 0.9375rem;
      }
      .count {
        display: flex;
        margin-bottom: 0.9375rem;
        font-size: 0.75rem;
        .count-l {
          width: 3.125rem;
          height: 1.25rem;
          line-height: 1.25rem;
          background: #f3cdb6;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #010804;
          p {
            margin-left: 2px;
          }
        }
        .count-r {
          width: 3.75rem;
          height: 1.25rem;
          line-height: 1.25rem;
          background: #484848;
          border-radius: 0px 4px 4px 0px;
          color: #f3cdb6;
          text-align: center;
        }
      }
      .open {
        color: #999999;
        font-size: 0.875rem;
        .date {
          margin-left: 0.3rem;
        }
        .time {
          margin-left: 0.5rem;
        }
      }
    }
  }
  .epmty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 11.4375rem;
    img {
      width: 6.5rem;
      height: 5.9375rem;
    }
    p {
      margin-top: 1.375rem;
      font-size: 1rem;
      font-family: NotoSansHans-Regular, NotoSansHans;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>